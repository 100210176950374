.home-form {
    /* Encapsulating the CSS rules within the .home-form selector */
    * {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    
    font-family: 'Nunito', sans-serif;
    color: #384047;
  }
  
  .home-form form {
    /* max-width: 300px; */
    margin: 10px;
    padding: 10px 20px;
    background: #f4f7f8;
    border-radius: 8px;
  }
  
  .home-form input[type="text"],
  .home-form input[type="password"],
  .home-form input[type="date"],
  .home-form input[type="datetime"],
  .home-form input[type="email"],
  .home-form input[type="number"],
  .home-form input[type="search"],
  .home-form input[type="tel"],
  .home-form input[type="time"],
  .home-form input[type="url"],
  .home-form textarea,
  .home-form select {
    background: rgba(255,255,255,0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px;
    width: 100%;
    background-color: #e8eeef;
    color: #8a97a0;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    margin-bottom: 30px;
  }
  
  .home-form input[type="radio"],
  .home-form input[type="checkbox"] {
    margin: 0 4px 8px 0;
  }
  
  .home-form select {
    padding: 6px;
    height: 32px;
    border-radius: 2px;
  }
  
  .home-form button {
    padding: 19px 39px 18px 39px;
    color: #FFF;
    background-color: #4bc970;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #3ac162;
    border-width: 1px 1px 3px;
    box-shadow: 0 -1px 0 rgba(255,255,255,0.1) inset;
    margin-bottom: 10px;
  }
  
  .home-form fieldset {
    margin-bottom: 30px;
    border: none;
  }
  
  .home-form legend {
    font-size: 1.4em;
    margin-bottom: 10px;
  }
  
  .home-form label {
    display: block;
    margin-bottom: 8px;
  }
  
  .home-form label.light {
    font-weight: 300;
    display: inline;
  }
  
  .home-form .number {
    background-color: #5fcf80;
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255,255,255,0.2);
    border-radius: 100%;
  }
  
  @media screen and (min-width: 480px) {
    .home-form form {
      max-width: 480px;
    }
  }
  