.output {
    /* Encapsulating the CSS rules within the .home-form selector */
    * {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    
    font-family: 'Nunito', sans-serif;
    color: #384047;
    /* max-width: 300px; */
    margin: 10px;
    padding: 10px 20px;
    background: #f4f7f8;
    border-radius: 8px;
  }

  .value-cell {
    font-family: 'Nunito', sans-serif;
    color: #384047;
    margin: 10px auto;
    padding: 10px auto;
  }

  .value-cell h3 {
    font-size: 16px;
  }

  .Collapsible__contentInner {
    padding: 10px;
    border: 0;
    border-color: black;
  }

  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #C13A99;
    color: white;
  
  
    &:after {
      font-family: 'FontAwesome';
      content: '\f107';
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
    }
  
    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
    
    
  }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue;
  }
  
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
  }